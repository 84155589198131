// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-default-page-js": () => import("../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-blog-post-page-js": () => import("../src/templates/blog-post-page.js" /* webpackChunkName: "component---src-templates-blog-post-page-js" */),
  "component---src-templates-blog-index-page-js": () => import("../src/templates/blog-index-page.js" /* webpackChunkName: "component---src-templates-blog-index-page-js" */),
  "component---src-templates-keynotes-page-js": () => import("../src/templates/keynotes-page.js" /* webpackChunkName: "component---src-templates-keynotes-page-js" */),
  "component---src-templates-organizers-page-js": () => import("../src/templates/organizers-page.js" /* webpackChunkName: "component---src-templates-organizers-page-js" */),
  "component---src-templates-papers-page-js": () => import("../src/templates/papers-page.js" /* webpackChunkName: "component---src-templates-papers-page-js" */),
  "component---src-templates-default-page-sec-navbar-js": () => import("../src/templates/default-page-sec-navbar.js" /* webpackChunkName: "component---src-templates-default-page-sec-navbar-js" */),
  "component---src-templates-sponsors-page-js": () => import("../src/templates/sponsors-page.js" /* webpackChunkName: "component---src-templates-sponsors-page-js" */),
  "component---src-templates-all-tutorials-page-js": () => import("../src/templates/all-tutorials-page.js" /* webpackChunkName: "component---src-templates-all-tutorials-page-js" */),
  "component---src-templates-all-workshops-page-js": () => import("../src/templates/all-workshops-page.js" /* webpackChunkName: "component---src-templates-all-workshops-page-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

